import { Component } from '@angular/core';

@Component({
  selector: 'app-cs2-tactics-board',
  standalone: true,
  imports: [],
  templateUrl: './cs2-tactics-board.component.html',
  styleUrl: './cs2-tactics-board.component.scss'
})
export class CS2TacticsBoardComponent {

}
