<div class="content">
    <h1 class="title" style="text-align: center;">Hello Ritskua~</h1>
    <p-divider></p-divider>
    <div class="card-container-container">
        <div class="card-container">
            <p-card [style]="cardStyle" [className]="'card'">
                <ng-template pTemplate="title">
                    <span class="title">Ritskua & Goos</span>
                </ng-template>

                <img src="../../../assets/images/VRChat_2023-09-08_15-13-50.775_1920x1080.png" alt="Ritskua" />
                <ng-template pTemplate="footer">
                    <!--Upvote/downvote icons with score in the middle-->
                    <div style="display:flex;gap:0.5rem;">
                        <div class="upvote-chip">
                            <p-selectButton [options]="upvoteOptions" optionLabel="label" optionValue="value"
                                [(ngModel)]="itemVoteStatus" [class]="upvoteButtonClass" (onOptionClick)="vote()">
                                <ng-template let-item pTemplate>
                                    <i [class]="item.icon" [style.color]="item.color"></i>
                                    <span>{{item.label}}</span>
                                </ng-template>
                            </p-selectButton>
                        </div>
                        <p-chip>
                            <span class="p-button-set">
                                <p-button icon="pi pi-comment" label="8" [text]="true"></p-button>
                            </span>
                        </p-chip>
                        <p-chip>
                            <span class="p-button-set">
                                <p-button icon="pi pi-share-alt" label="Share" [text]="true"></p-button>
                            </span>
                        </p-chip>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="card-container-container">
        <div class="card-container">
            <p-card [style]="cardStyle" [className]="'card'">
                <ng-template pTemplate="title">
                    <span class="title">Don't do it</span>
                </ng-template>
                <div style="display: flex; justify-content: center; margin:0.5rem" class="card-content">
                    <video src="../../../assets/videos/Video_uden_titel_-_Skabt_med_Clipchamp_1.mp4" controls></video>
                </div>
                <ng-template pTemplate="footer">
                    <!--Upvote/downvote icons with score in the middle-->
                    <div style="display:flex;gap:0.5rem;">
                        <div class="upvote-chip">
                            <p-selectButton [options]="upvoteOptions" optionLabel="label" optionValue="value"
                                [(ngModel)]="itemVoteStatus" [class]="upvoteButtonClass" (onOptionClick)="vote()">
                                <ng-template let-item pTemplate>
                                    <i [class]="item.icon" [style.color]="item.color"></i>
                                    <span>{{item.label}}</span>
                                </ng-template>
                            </p-selectButton>
                        </div>
                        <p-chip>
                            <span class="p-button-set">
                                <p-button icon="pi pi-comment" label="8" [text]="true"></p-button>
                            </span>
                        </p-chip>
                        <p-chip>
                            <span class="p-button-set">
                                <p-button icon="pi pi-share-alt" label="Share" [text]="true"></p-button>
                            </span>
                        </p-chip>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </div>