import { Component, OnInit } from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MenubarModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})

export class NavbarComponent implements OnInit {
  items: MenuItem[] | undefined;
  flagsMenu: MenuItem[] | undefined;
  position: 'bottom' | 'top' | 'left' | 'right' = 'bottom';

  ngOnInit() {
    this.items = [
      {
        label: 'Home',
        routerLink: '/',

      },
      {
        label: 'Ritskua',
        routerLink: '/ritskua',
      },
      {
        label: 'Lovvvyy',
        routerLink: '/lovvvyy',
      },
      {
        label: 'Retro',
        routerLink: '/retro',
      },
      {
        label: 'Playbook',
        routerLink: '/playbook',
      },
      {
        label: 'Skins',
        routerLink: '/skins',
        items: [
          {
            label: 'Home',
            routerLink: '/skins/home',
          },
          {
            label: 'Windows 95',
            routerLink: '/skins/win95',
          }
        ]
      }
    ];

    this.flagsMenu = [
      
    ];
  }
}
