import { Component } from '@angular/core';
import { MenubarModule } from 'primeng/menubar';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MenubarModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  menuStyle = {
    position: 'absolute',
    top: '0px',
    width: '100%',
    borderRadius: '0px',
    height: '28px',
  }

  items = [
    {
      label: 'Home',
      items: [
        {
          label: 'Away',
          style: {
            maxHeight: '28px',
            padding: 'none',
          },
          styleClass: 'menuBarItem'
        },
      ]
    }
  ]
}
