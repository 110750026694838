<div class="content">
    <h1 class="title">Welcome to GoosLife</h1>
    <div class="text-content-main">
        <img src="../../assets/brandkit/Logo/SVG/main-logo-white-transparent.svg" class="Logo-image">
        <p>
            GoosLife is a developer, gamer, VR enthusiast and storyteller.
            <br /><br />
            With a deep understanding of virtual reality technologies and a love for immersive experiences, GoosLife is
            constantly exploring new possibilities in the virtual world.
            <br /><br />
            Whether it's creating unique worlds, designing
            interactive environments, or organizing virtual events, GoosLife is dedicated to pushing the boundaries of
            what VR can offer. Join the journey and discover a world of endless possibilities with GoosLife.
        </p>
    </div>
</div>