import { Routes } from '@angular/router';

import { NavbarComponent } from './components/navbar/navbar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { LandingPageComponent } from './landing-page/landing-page.component';
import { RitskuaComponent } from './components/ritskua/ritskua.component';
import { LovvvyyComponent } from './components/lovvvyy/lovvvyy.component';
import { RetroComponent } from './components/retro/retro.component';
import { Windows95Component } from './components/skins/windows95/windows95.component';
import { PlaybookComponent } from './components/playbook/playbook.component';
import { HomeComponent } from './components/skins/home/home.component';
import { CSGOClassicMatchesComponent } from './components/csgoclassic-matches/csgoclassic-matches.component';

export const routes: Routes = [
    { path: '', component: LandingPageComponent },
    { path: 'ritskua', component: RitskuaComponent },
    { path: 'lovvvyy', component: LovvvyyComponent },
    { path: 'retro', component: RetroComponent },
    { path: 'playbook', component: PlaybookComponent },
    { path: 'skins', component: HomeComponent, children: [
        { path: '', component: HomeComponent },
        { path: 'home', component: HomeComponent },
        { path: 'win95', component: Windows95Component },
        { path: 'windows95', component: Windows95Component },
        { path: '**', component: PageNotFoundComponent }
    ]},
    { path: 'classic-matches', component: CSGOClassicMatchesComponent },
    { path: '**', component: PageNotFoundComponent }
];
