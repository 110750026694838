<p-menubar [model]="items" class="menu-bar">
    <ng-template pTemplate="start">
        <div class="menu-items">
            <img src="../../../assets\brandkit\Logo\PNG\Favicon-32x32.png" class="logo" routerLink="/"
                style="cursor:pointer;">
        </div>
    </ng-template>
    <ng-template pTemplate="end">
        <div class="icons menu-items">
            <i class="pi pi-video"></i>
            <i class="pi pi-wifi"></i>
            <i class="pi pi-volume-up"></i>
            <span>Fri 13:07</span>
            <i class="pi pi-search"></i>
            <!-- <i class="pi pi-bars"></i> -->
            <div class="mobile-menu">

            </div>
        </div>
    </ng-template>
</p-menubar>