import { Component } from '@angular/core';

@Component({
  selector: 'app-csgoclassic-matches',
  standalone: true,
  imports: [],
  templateUrl: './csgoclassic-matches.component.html',
  styleUrl: './csgoclassic-matches.component.scss'
})
export class CSGOClassicMatchesComponent {

}
