import { Component } from '@angular/core';

@Component({
  selector: 'app-playbook',
  standalone: true,
  imports: [],
  templateUrl: './playbook.component.html',
  styleUrl: './playbook.component.scss'
})
export class PlaybookComponent {

}
