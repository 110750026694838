import { Component } from '@angular/core';

import { CS2TacticsBoardComponent } from '../cs2-tactics-board/cs2-tactics-board.component';

@Component({
  selector: 'app-lovvvyy',
  standalone: true,
  imports: [
    CS2TacticsBoardComponent
  ],
  templateUrl: './lovvvyy.component.html',
  styleUrl: './lovvvyy.component.scss'
})
export class LovvvyyComponent {

}
