import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { ChipModule } from 'primeng/chip';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-ritskua',
  standalone: true,
  imports: [
    FormsModule,
    CardModule,
    DividerModule,
    ChipModule,
    ButtonModule,
    SelectButtonModule
  ],
  templateUrl: './ritskua.component.html',
  styleUrl: './ritskua.component.scss'
})
export class RitskuaComponent {
  cardStyle: Object = { color: '#dedede' }
  cardStyleClass: string = 'card';
  score: string = (2).toString();
  upvoteOptions: Object[] = [
    {
      icon: 'pi pi-arrow-up',
      value: 'upvote',
      color: 'green'

    },
    {
      label: this.score,
      value: this.score,
      disabled: true
    },
    {
      icon: 'pi pi-arrow-down',
      value: 'downvote',
      color: 'red'
    }
  ]
  itemVoteStatus = 'none';
  upvoteButtonClass = 'upvote-buttons';

  vote() {
    console.log('upvote-buttons-' + this.itemVoteStatus);
    this.upvoteButtonClass = 'upvote-buttons-' + this.itemVoteStatus;

    if (this.itemVoteStatus === 'upvote') {
      this.score = '3';
    }
    else if (this.itemVoteStatus === 'downvote') {
      this.score = '1';
    }
    else {
      this.score = '2';
    }

    this.upvoteOptions = [
      {
        icon: 'pi pi-arrow-up',
        value: 'upvote',
        color: 'green'

      },
      {
        label: this.score,
        value: this.score,
        disabled: true
      },
      {
        icon: 'pi pi-arrow-down',
        value: 'downvote',
        color: 'red'
      }
    ]
  }
}